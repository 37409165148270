var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { VictoryChart, VictoryLegend, VictoryAxis, VictoryStack, VictoryBar, VictoryLabel, VictoryVoronoiContainer, } from 'victory';
import moment from 'moment-timezone';
import { getHourMin, getHourMinSec, roundOff } from 'globals/global.functions';
import { DATE_FORMAT_SHORT } from 'globals/global.constants';
import { Box, useTheme } from '@mui/material';
var ActivityChart = function (_a) {
    var activityChartData = _a.activityChartData, currentState = _a.currentState, maxDomain = _a.maxDomain;
    var theme = useTheme();
    var legendChartData = [
        {
            name: 'Moderate',
            symbol: { fill: theme.palette.customColor.moderateGreen, type: 'square' },
        },
        {
            name: 'Intense',
            symbol: { fill: theme.palette.customColor.intenseGreen, type: 'square' },
        },
    ];
    return (_jsx(Box, { mt: -5, mb: -5, children: _jsxs(VictoryChart, { domainPadding: { x: 50, y: 20 }, maxDomain: { y: currentState == 'day' ? maxDomain + 10 : maxDomain + 30 }, width: 1000, height: 435, containerComponent: _jsx(VictoryVoronoiContainer, { activateLabels: true, labels: function (data) {
                    return data;
                }, labelComponent: _jsx(Tooltip, { data: activityChartData, currentState: currentState }) }), children: [_jsx(VictoryLegend, { orientation: 'horizontal', x: 350, y: 5, data: legendChartData }), _jsx(VictoryAxis, { orientation: 'bottom', style: {
                        axis: {
                            stroke: theme.palette.customColor.borderGrey, //CHANGE COLOR OF Y-AXIS
                        },
                    }, tickFormat: function (data) {
                        return moment(data).format(currentState == 'day' ? 'h A' : DATE_FORMAT_SHORT);
                    }, fixLabelOverlap: true }), _jsx(VictoryAxis, { orientation: 'right', dependentAxis: true, style: {
                        axis: {
                            stroke: theme.palette.customColor.borderGrey, //CHANGE COLOR OF Y-AXIS
                        },
                    }, 
                    //tickValues={currentState == 'day' ? [] : yTicks}
                    minDomain: { y: 0 }, maxDomain: { y: 40 }, tickFormat: function (data, i) {
                        return currentState == 'day' ? data : "".concat(roundOff(data / 60));
                    }, label: currentState == 'day' ? 'Minutes' : 'Hours', axisLabelComponent: _jsx(VictoryLabel, { angle: 0, dy: -200, dx: -20, style: {
                            fontWeight: 600,
                            fontSize: 15,
                            fill: theme.palette.customColor.titleBlack,
                        } }) }), _jsx(VictoryStack, { colorScale: [
                        theme.palette.customColor.moderateGreen,
                        theme.palette.customColor.intenseGreen,
                    ], children: activityChartData.map(function (chartData) { return (_jsx(VictoryBar, __assign({ data: chartData }, (currentState === 'week' ? { barWidth: 70 } : {})), chartData.x + chartData.y)); }) })] }) }));
};
var Tooltip = function (props) {
    var theme = useTheme();
    var x = props.x, data = props.data, datum = props.datum, currentState = props.currentState;
    var width = 140;
    var date = datum.x;
    var actual = [0, 0];
    if (data.length) {
        actual = data.map(function (val) {
            var exact = val.filter(function (fil) { return fil.x == date; });
            return exact[0].y;
        });
    }
    if (actual[0] == 0 && actual[1] == 0)
        return null;
    return (_jsxs("g", { transform: "translate(".concat(x - 64.5, ", 0)"), children: [_jsxs("g", { transform: 'translate(0, 0)', children: [_jsx("rect", { height: 60, width: width, stroke: theme.palette.background.green, fill: theme.palette.background.green, ry: 3, rx: 3 }), _jsx("text", { dx: 5, dy: 16, alignmentBaseline: 'inherit', textAnchor: 'start', fontSize: 14, fill: theme.palette.customColor.info, children: _jsx("tspan", { children: moment(date).format(currentState == 'day' ? 'h A' : 'MMMM D') }) }), _jsx("circle", { cy: 30, cx: 10, r: 5, strokeWidth: 1, stroke: theme.palette.customColor.strokeGrey, fill: theme.palette.customColor.moderateGreen }), _jsxs("text", { dx: 20, dy: 34, alignmentBaseline: 'inherit', textAnchor: 'start', fontSize: 12, fill: theme.palette.common.black, children: [_jsx("tspan", { children: "MODERATE" }), _jsx("tspan", { dx: 9, children: getHourMinSec(actual[0], 'm', 'short') })] }), _jsx("circle", { cy: 46, cx: 10, r: 5, strokeWidth: 1, stroke: theme.palette.customColor.strokeGrey, fill: theme.palette.customColor.intenseGreen }), _jsxs("text", { dx: 20, dy: 50, alignmentBaseline: 'middle', textAnchor: 'start', fontSize: 12, fill: 'rgb(0,0,0)', children: [_jsx("tspan", { children: "INTENSE" }), _jsx("tspan", { dx: 21, children: getHourMin(actual[1], 'm', 'short') })] })] }), _jsx("g", { transform: "translate(".concat(width / 2, ", -16)"), children: _jsx("polygon", { points: '0,85 -6,76 8,76', stroke: theme.palette.background.green, fill: theme.palette.background.green, strokeWidth: '1' }) })] }, 'tooltip'));
};
export { ActivityChart };
